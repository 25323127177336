import React, { useState, useEffect } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import { ReactNotifications, Store } from "react-notifications-component";
import {
  ListContryCitys,
  PostupHotelView,
  postAllCompaniesRoll,
  getBranchDataByCompany,
  GetInfoDestinos,
  PostDeleteDestin,
} from "../../utils/urls.js";
import {
  getListhotelsview,
  deleteHotelSelect,
} from "../../utils/urlExperience.js";
import { getFromLocalStorage } from "../../components/helps.js";
import axios from "axios";
import DataTableConsulting from "../../components/objects/tablesConsulta.tsx";
import EditDestinations from "./experience-editdestinations.js";

function BestValueOffers({ idUser, infoUser, dataUser }) {
  let token = getFromLocalStorage("TK");
  let idUserP = getFromLocalStorage("Session");

  const header = ["id", "nombre", "urlimagen", "statename", "show"];
  const headerMap = {
    id: "Id",
    nombre: "Name",
    urlimagen: "Image",
    statename: "Country",
    show: "Status",
  };

  const initSelec2 = { value: 0, text: "Select an option" };
  const initCountrys = {
    country: "",
    citys: [
      {
        city: "",
        citycode: 0,
      },
    ],
  };

  const initHotels = {
    idbvo: 0,
    nombredestino: "",
    urlimagen: "",
    place: "",
    statename: "",
    show: false,
    idcliente: 0,
  };

  const initDestin = {
    id: 0,
    nombre: "",
    urlimagen: "",
    place: "",
    statename: "",
    show: false,
    id_empresa: 0,
    id_sucursal: 0,
  };

  const initSelec = { value_option: 0, name_option: "Select an option" };

  const [idCompanys, setidCompanys] = useState([initSelec2]);
  // const [slsCompany, setslsCompany] = useState([initSelec]);
  const [slsCompany, setslsCompany] = useState([]);

  const [slsCompanyArray, setslsCompanyArray] = useState([initCountrys]);
  const [slsHotelsArray, setslsHotelsArray] = useState([initHotels]);
  //   const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [loading, setLoading] = useState(false); // Estado de carga
  const [reload, setReload] = useState(false);

  const initSelec3 = { name: "", lastname: "", email: "", userId: "", rol: "" };
  const [formvisible, setformvisible] = useState(false);
  const [formvisibleAddDestini, setformvisibleAddDestini] = useState(true);


  const [isLoaded, setIsLoaded] = useState(true);
  const [formValues, setFormValues] = useState({
    nombredestino: "",
    urlimagen: null,
    place: "",
    statename: "",
    show: true,
    // idcliente: initSelec.value_option,
    idcliente: initSelec.value_option,
    company: "",
    branch: "",
    useForAllBranches: false, // Nuevo campo para el checkbox
  });

  const [formValuesSelects, setFormValuesSelects] = useState({
    company: "",
    branch: "",
  });

  const [slsDataDestinos, setslsDataDestinos] = useState([initDestin]);

  const [data, setData] = useState([]);

  const [slsRoll, setslsRoll] = useState([initSelec2]);
  const [slsBranch, setslsBranches] = useState([initSelec2]);
  const [slsBranchesViews, setslsBranchesViews] = useState([initSelec2]);

  const [slsUsersbyComp, setslsUsersbyComp] = useState([initSelec3]);

  const [tableValues, setTableValues] = useState({ company: "" });
  const [isPanel1Visible, setIsPanel1Visible] = useState(false);

  const [cityOptions, setCityOptions] = useState([]); // Nuevo estado para las ciudades
  const [idRegistro, setidRegistro] = useState("");
  const [selectedRegister, setSelectedRegister] = useState(null);

  useEffect(() => {
    // console.log('<<<<<<isLoaded>>>>>>');
    // console.log(isLoaded);

    const executeFetchLoadCombos = async () => {
      try {
        if (isLoaded === true) {
          // Ejecutar fetchLoadCombos y esperar a que termine
          await fetchLoadCombos();

          console.log("fetchLoadCombos ejecutado con éxito");

          const fetchBranches = async () => {
            const branches = await fnGetAllBranch2();
            setslsBranchesViews([initSelec2, ...branches]);
          };
          fetchBranches();
        }

      } catch (error) {
        console.error("Error en fetchLoadCombos:", error);
      } finally {
        // Cambiar el estado a false, independientemente de si hubo error o no
        setIsLoaded(false);
      }
    };

    executeFetchLoadCombos();
  }, [isLoaded]);

  // useEffect(() => {
  //   if (formValues.idcliente) {
  //     const fetchBranches = async () => {
  //       const branches = await fnGetAllBranch();
  //       setslsBranches([initSelec2, ...branches]);
  //     };
  //     fetchBranches();
  //   }
  // }, [formValues.idcliente]);

  useEffect(() => {
    if (formValues.idcliente) {
      const fetchBranches = async () => {
        try {
          const branches = await fnGetAllBranch();
          if (branches && Array.isArray(branches)) {
            setslsBranches([initSelec2, ...branches]);
          } else {
            setslsBranches([initSelec2]); // Solo muestra la opción predeterminada si no hay datos
          }
        } catch (error) {
          console.error("Error al cargar los combos", error);
          setslsBranches([initSelec2]); // Muestra solo la opción predeterminada en caso de error
        }
      };
      fetchBranches();
    } else {
      setslsBranches([initSelec2]); // Resetea el select si no hay un cliente seleccionado
    }
  }, [formValues.idcliente]);

  useEffect(() => {
    if (formValuesSelects.company) {
      const fetchBranches = async () => {
        const branches = await fnGetAllBranch2();
        setslsBranchesViews([ initSelec2, ...branches]);
      };
      fetchBranches();
      fetchInfoDestinos();
    }

    if (formValuesSelects.branch) {


      fetchInfoDestinos();
    }
  }, [formValuesSelects.company, formValuesSelects.branch]);


  const fetchInfoDestinos = async () => {
    const destinations = await fnPostInfoDestios();

    // Renombrar `id_destino` a `id`
    const renamedDestinations = destinations.map((destino) => ({
      ...destino,
      id: destino.id_destino, // Crear la nueva clave `id`
    }));

    // Guardar en el estado
    setslsDataDestinos(renamedDestinations);
  };


  const fnGetAllBranch = async () => {
    try {
      const requestData = {
        companyId: formValues.idcliente || idUserP.company, // Si formValues.company es vacío o no existe, usa idUserP.company
      };

      const responseJsonCompanies = await axios.post(
        getBranchDataByCompany(token),
        requestData
      );
      return responseJsonCompanies.data.data || []; // Devuelve un array vacío si no hay datos
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
      return []; // Devuelve un array vacío en caso de error
    }
  };

  const fnPostInfoDestios = async () => {
    try {
      const requestData = {
        id_empresa: formValuesSelects.company || idUserP.company, // Si formValues.company es vacío o no existe, usa idUserP.company
        id_sucursal: formValuesSelects.branch || 0,
      };

      const responseJsonCompanies = await axios.post(
        GetInfoDestinos(token),
        requestData
      );
      return responseJsonCompanies.data.data || []; // Devuelve un array vacío si no hay datos
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
      return []; // Devuelve un array vacío en caso de error
    }
  };

  const fnGetAllBranch2 = async () => {
    try {
      const requestData = {
        companyId: formValuesSelects.company || idUserP.company, // Si formValues.company es vacío o no existe, usa idUserP.company
      };

      const responseJsonCompanies = await axios.post(
        getBranchDataByCompany(token),
        requestData
      );
      return responseJsonCompanies.data.data || []; // Devuelve un array vacío si no hay datos
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
      return []; // Devuelve un array vacío en caso de error
    }
  };

  const fetchLoadCombos = async () => {
    console.log("en funcion de combos <<<<<<<<<<<<<<");

    console.log(!isLoaded);
    try {
      //   if (!isLoaded) {
      if (token === undefined || token === null) {
        token = getFromLocalStorage("TK");
      }
      let selectCombos = [initSelec, ...(await fnPostCompaniesList())];
      // let selectCombos = [ ...(await fnPostCompaniesList())];

      let selectContrys = [initCountrys, ...(await listContrysCitys())];
      let selectHotels = [initHotels, ...(await getListHotelsavailables())];
      console.log("<<<<<<<<<<<<<<<<<<selectHotels>>>>>>>>>>>>>>>>>>");

      console.log(selectHotels);

      setslsCompany(selectCombos);
      setslsCompanyArray(selectContrys);
      setslsHotelsArray(selectHotels);

      //   }
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };

  const getListHotelsavailables = async () => {
    try {
      //   if (!isLoaded) {
      if (token === undefined || token === null) {
        token = getFromLocalStorage("TK");
      }

      const responseJsonHotelss = await axios.get(getListhotelsview());
      console.log(
        "<<<<<<<<<<<<<<<<<<<<<<responseJsonHotelss>>>>>>>>>>>>>>>>>>>>>>"
      );

      console.log(responseJsonHotelss.data);
      return responseJsonHotelss.data;

      //   }
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };

  const listContrysCitys = async () => {
    try {
      const responseJsonContrys = await axios.get(ListContryCitys());
      return responseJsonContrys.data;
    } catch (error) {
      console.error("Error al cargar los combos  11", error);
    }
  };

  const fnPostCompaniesList = async () => {
    try {
      const requestData = {
        id_rol: idUserP.rol,
        id_empresa: idUserP.company,
        id_sucursal: idUserP.branch,
      };

      const responseJsonCompanies = await axios.post(
        postAllCompaniesRoll(token),
        requestData
      );

      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setFormValues((prevValues) => ({
      ...prevValues,
      statename: selectedCountry,
      place: "", // Reiniciar el lugar al cambiar de país
    }));

    // Filtrar las ciudades correspondientes al país seleccionado
    const selectedCountryData = slsCompanyArray.find(
      (country) => country.country === selectedCountry
    );
    if (selectedCountryData) {
      setCityOptions(selectedCountryData.citys); // Actualizar las ciudades
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormValues((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
        ...(name === "idcliente" && { branch: "" }),
      }));
    }
  };

  const validateForm = () => {
    return (
      formValues.nombredestino && formValues.urlimagen && formValues.statename
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      addNotification(
        "danger",
        "Error",
        "Fill all required fields",
        "top-center"
      );
      return;
    }

    try {
      // Crea una función para realizar el POST
      const postHotel = async (branchId) => {
        const formData = new FormData();
        formData.append("nombre", formValues.nombredestino);
        formData.append("urlimagen", formValues.urlimagen); // Agrega el archivo
        formData.append("place", formValues.place);
        formData.append("statename", formValues.statename);
        formData.append("show", formValues.show);
        formData.append("id_empresa", formValues.idcliente);
        formData.append("id_sucursal", branchId);

        const response = await fetch(PostupHotelView(token), {
          method: "POST",
          body: formData,
        });

        return response;
      };

      if (formValues.useForAllBranches) {
        // Itera sobre todas las sucursales y envía las peticiones


        const promises = slsBranch
          .slice(1)
          .map((branch) => postHotel(branch.value));
        const responses = await Promise.all(promises);

        const successfulResponses = responses.filter(
          (res) => res.status === 201
        );
        if (successfulResponses.length === slsBranch.length - 1) {
          addNotification("success", "Success", "Records saved", "top-center");
        } else {
          addNotification(
            "warning",
            "Partial Success",
            "Some records were not saved",
            "top-center"
          );
        }
      } else {
        // Si no está activado "Use for all branches", realiza un solo POST
        const response = await postHotel(formValues.branch);
        const data = await response.json();

        if (response.status === 201) {
          addNotification("success", "Success", "Record saved", "top-center");
        } else {
          addNotification(
            "warning",
            "Error",
            "Error, some data is missing",
            "top-center"
          );
        }
      }

      // Reinicia los valores del formulario
      setFormValues({
        nombredestino: "",
        urlimagen: null,
        place: "",
        statename: "",
        show: true,
        idcliente: "",
        // idcliente: initSelec.value_option,
        branch: "",
        useForAllBranches: false,
      });

      fetchLoadCombos();
      setIsLoaded(true);
    } catch (error) {
      console.error("Error:", error);
      addNotification(
        "danger",
        "Error",
        "An unexpected error occurred",
        "top-center"
      );
    }
  };

  const handleCheckboxChange = (idbvo, isChecked) => {
    setSelectedIds((prev) =>
      isChecked ? [...prev, idbvo] : prev.filter((id) => id !== idbvo)
    );
  };

  const handleDeleteSelection = async () => {
    console.log("ID seleccionados:", selectedIds);

    // Filtrar el array para eliminar los seleccionados
    setslsHotelsArray(
      slsHotelsArray.filter((hotel) => !selectedIds.includes(hotel.idbvo))
    );

    try {
      // Iterar sobre selectedIds y enviar la petición GET para cada ID
      const responses = await Promise.all(
        selectedIds.map((id) => axios.get(deleteHotelSelect(id)))
      );

      // Verificar que todas las respuestas sean exitosas
      const allSuccessful = responses.every(
        (response) => response.status === 200
      );

      if (allSuccessful) {
        console.log("Registros dados de baja.");
        addNotification("success", "Success", "Records deleted", "top-center");
      } else {
        addNotification(
          "warning",
          "Error",
          "Error updating one or more recordss",
          "top-center",
          false
        );
      }
    } catch (error) {
      console.error("Error al actualizar registros:", error.message);
    }

    setSelectedIds([]); // Limpiar selección
  };

  function addNotification(
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition,
    notificationContent
  ) {
    if (notificationContent) {
      notificationContent = (
        <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
          <div className="flex-1 ps-2">
            <h6 className="mb-1">Christopher Struth</h6>
            <p className="mb-0">Bank Transfer</p>
          </div>
        </div>
      );
    }
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
      content: notificationContent,
    });
  }

  const toggleReload = () => {
    if (!reload) {
      setReload(true);
      setLoading(true); // Inicia la carga cuando se hace clic en recargar

      // Restablecer slsHotelsArray a su valor inicial
      setTimeout(() => {
        setIsLoaded(true);

        setReload(false);
        setLoading(false); // Termina la carga
      }, 2000);
    }
  };

  const handleInputChangeCompany = (e) => {
    const { name, value } = e.target;
    setFormValuesSelects((prevValues) => ({
      ...prevValues,
      [name]: value,
      ...(name === "company" && { branch: "" }), // Reiniciar branch si cambia la compañía
    }));
  };

  const fnViewDetail = (idRegister) => {};

  const fnEditRegister = (register) => {
    console.log("Registro recibido para editar:", register); // Imprime el registro completo
    setidRegistro(register.id); // Guarda el ID
    setformvisible(true);
    setSelectedRegister(register); // Guarda el registro completo
    setformvisibleAddDestini(false);
  };


  const fnDeleteRegister = async (idRegister) => {

    console.log('<<<<<<<<<<<<<<<<<<<<<', idRegister)
    console.log('eliminar registro de base. << ')

    try {
      const requestData = {
        id_destino:  idRegister, 
      };

      const responseJsonCompanies = await axios.post(
        PostDeleteDestin(token),
        requestData
      );

      console.log('<<<<<<<<<<responseJsonCompanies>>>>>>>>>>')

      console.log(responseJsonCompanies)

      if(responseJsonCompanies.status == 201){
        addNotification("success", "Success", responseJsonCompanies.data.data.message, "top-center");
        fetchInfoDestinos();

      }else{
        addNotification(
          "warning",
          "Partial Success",
          responseJsonCompanies.data.data.message,
          "top-center"
        );
      }

    } catch (error) {
      console.error("Error al cargar los combos 333", error);
      return []; 
    }

  };


  console.log('<<<<<<<<<<slsBranch>>>>>>>>>>')
  console.log(slsBranch)

  return (
    <div>
      <div className="row mb-3">

        <div className="col-xl-6" style={!formvisibleAddDestini ? { display: "none" } : {}}>
          <Panel>
            <PanelHeader>Add hotels to display</PanelHeader>
            <PanelBody>
              <form onSubmit={handleSubmit}>
                <div className="row mb-3 d-flex align-items-center">
                  <label
                    className="form-label col-form-label col-md-3 mb-0"
                    htmlFor="nombredestino"
                  >
                    Name destination*
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control"
                      type="text"
                      id="nombredestino"
                      name="nombredestino"
                      value={formValues.nombredestino}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="row mb-3 d-flex align-items-center">
                  <label
                    className="form-label col-form-label col-md-3 mb-0"
                    htmlFor="urlimagen"
                  >
                    Select an image*
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control"
                      type="file"
                      id="urlimagen"
                      name="urlimagen"
                      //   value={formValues.urlimagen}
                      onChange={handleInputChange} // No es necesario pasar el value porque es un campo de tipo archivo
                    />
                  </div>
                </div>

                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="statename"
                  >
                    Statename*
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select mb-5px"
                      id="statename"
                      name="statename"
                      value={formValues.statename}
                      onChange={handleCountryChange}
                    >
                      {slsCompanyArray.map((option, index) => (
                        <option key={"form_" + index} value={option.country}>
                          {option.country}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="place"
                  >
                    Place*
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select mb-5px"
                      id="place"
                      name="place"
                      value={formValues.place}
                      onChange={handleInputChange}
                    >
                      {cityOptions.map((city, index) => (
                        <option key={index} value={city.citycode}>
                          {city.city}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="idcliente"
                  >
                    Companies*
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select mb-5px"
                      id="idcliente"
                      name="idcliente"
                      value={formValues.idcliente || 12345}
                      onChange={handleInputChange}
                    >
                      {slsCompany.map((option, index) => (
                        <option
                          key={"form_" + index}
                          value={option.value_option}
                        >
                          {option.name_option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {(idUserP.rol === 1 ||
                  idUserP.rol === 2 ||
                  idUserP.rol === 4 ||
                  idUserP.rol === 6) && (
                  <div className="row md-15px">
                    <label
                      className="form-label col-form-label col-md-3"
                      htmlFor="branch"
                    >
                      Branches
                    </label>
                    <div className="col-md-9">
                      <select
                        className="form-select mb-5px"
                        id="branch"
                        name="branch"
                        value={formValues.branch}
                        onChange={handleInputChange}
                      >
                        {slsBranch.map((option, index) => (
                          <option key={"form_" + index} value={option.value}>
                            {option.text}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}

                {formValues.idcliente && (
                  <div className="row md-15px">
                    <label
                      className="form-label col-form-label col-md-3"
                      htmlFor="useForAllBranches"
                    >
                      Use for all branches
                    </label>
                    <div className="col-md-9 d-flex align-items-center">
                      <input
                        className="form-check-input is-valid"
                        type="checkbox"
                        id="useForAllBranches"
                        name="useForAllBranches"
                        style={{ marginRight: "10px" }}
                        checked={formValues.useForAllBranches || false}
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            useForAllBranches: e.target.checked,
                          })
                        }
                      />
                    </div>
                  </div>
                )}

                <div className="lrow md-15px" style={{ margin: 10 }}>
                  <button
                    type="submit"
                    className="btn btn-green"
                    style={{ float: "right" }}
                  >
                    + Add destination
                  </button>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>

      </div>
      <div className="row mb-3">
        <Panel>
          <PanelHeader>
            &nbsp;&nbsp;
            <button
              className="btn btn-xs btn-icon btn-circle btn-success"
              onClick={toggleReload}
            >
              <i className="fa fa-redo"></i>
            </button>
            &nbsp;&nbsp; List of destinations to display
          </PanelHeader>
          <PanelBody>
            <div className="lrow md-7px" style={{ margin: 2 }}>
              *The first 4 records will be displayed
            </div>
            <div>
              <div>
                {/* Condición para mostrar el div de Companies y Branches si rol es 1 o 2 */}
                {(idUserP.rol === 1 || idUserP.rol === 2) && (
                  <div className="row md-15px">
                    <label
                      className="form-label col-form-label col-md-3"
                      htmlFor="company"
                    >
                      Companies*
                    </label>
                    <div className="col-md-9">
                      <select
                        className="form-select mb-5px"
                        id="company"
                        name="company"
                        value={formValuesSelects.company}
                        onChange={handleInputChangeCompany}
                      >
                        {slsCompany.map((option, index) => (
                          <option
                            key={"form_" + index}
                            value={option.value_option}
                          >
                            {option.name_option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}

                {/* Condición para mostrar el div de Branches si rol es 1, 2, 4 o 6 */}
                {(idUserP.rol === 1 ||
                  idUserP.rol === 2 ||
                  idUserP.rol === 4 ||
                  idUserP.rol === 6) && (
                  <div className="row md-15px">
                    <label
                      className="form-label col-form-label col-md-3"
                      htmlFor="branch"
                    >
                      Branches
                    </label>
                    <div className="col-md-9">
                      <select
                        className="form-select mb-5px"
                        id="branch"
                        name="branch"
                        value={formValuesSelects.branch}
                        onChange={handleInputChangeCompany}
                      >
                        {slsBranchesViews.map((option, index) => (
                          <option key={"form_" + index} value={option.value}>
                            {option.text}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              </div>

              <br></br>
              <div className="row col-md-12">
                <DataTableConsulting
                  header={header}
                  headerMap={headerMap}
                  info={slsDataDestinos}
                  onViewDetail={fnViewDetail}
                  onEditRegister={fnEditRegister}
                  onDelete={fnDeleteRegister}
                />
              </div>
            </div>
          </PanelBody>
        </Panel>{" "}
      </div>

      <div className="row" style={!formvisible ? { display: "none" } : {}}>
  <div className="col-12">
    <Panel>
      <PanelHeader>Data Users New</PanelHeader>
      <PanelBody>
        {formvisible && (
          <EditDestinations id={idRegistro} infoRegister={selectedRegister} />
        )}
      </PanelBody>
    </Panel>
  </div>
</div>



      <ReactNotifications />{" "}
    </div>
  );
}

export default BestValueOffers;
