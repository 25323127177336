import React, { useState, useEffect } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import { ReactNotifications } from "react-notifications-component";
import {
  getBranchDataByCompany,
  PostCombosRol,
  postAllCompaniesRoll,
  postRequesReserv,
  postInfoSales,
} from "../../utils/urls.js";
import { getFromLocalStorage } from "../../components/helps.js";
import axios from "axios";
import DataTableConsulting from "../../components/objects/tablesConsulta.tsx";
import { Modal, Button } from "react-bootstrap";

function ReportView({ idUser, infoUser, dataUser }) {
  let token = getFromLocalStorage("TK");
  let idUserP = getFromLocalStorage("Session");

  const header = [
    "payment_status",
    "idplataforma",
    "fecha_compra",
    "hora_compra",
    "iduser",
    "company_name",
    "name_hotel",
  ];

  const headerMap = {
    payment_status: "Estado de Pago",
    idplataforma: "ID Plataforma",
    fecha_compra: "Fecha de Compra",
    hora_compra: "Hora de Compra",
    iduser: "ID Usuario",
    company_name: "Nombre de la Compañía",
    name_hotel: "Nombre del Hotel",
  };

  const selectOptions = [
    {label: "Select your type filter", value: "Select Filter" },

    {label: "name_hotel", value: "name_hotel" },
    // {label: "url_hoteli", value: "url_hoteli" },
    {label: "hotelcode", value: "hotelcode" },
    {label: "roomdescription", value: "roomdescription" },
    // {label: "confirmpropertycode", value: "confirmpropertycode" },
    // {label: "confirmation_number", value: "confirmation_number" },
    {label: "precio_noche", value: "precio_noche" },
    {label: "precio_total", value: "precio_total" },
    {label: "precio_total_withcomission", value: "precio_total_withcomission" },
    // {label: "taxes", value: "taxes" },
    {label: "nombre", value: "nombre" },
    {label: "last_name", value: "last_name" },
    {label: "number_movil", value: "number_movil" },
    {label: "email", value: "email" },
    {label: "city", value: "city" },
    {label: "nationality", value: "nationality" },
    // {label: "idboletogroup", value: "idboletogroup" },
    {label: "l_address", value: "l_address" },
    // {label: "l_latitude", value: "l_latitude" },
    // {label: "l_longitude", value: "l_longitude" },
    {label: "ls_city", value: "ls_city" },
    {label: "ls_idevent", value: "ls_idevent" },
    {label: "ls_typesupplier", value: "ls_typesupplier" },
    {label: "ls_numtickts", value: "ls_numtickts" },
    {label: "price_tix", value: "price_tix" },
    {label: "price_v", value: "price_v" },
    {label: "section_v", value: "section_v" },
    {label: "row_v", value: "row_v" },
    {label: "name_evento", value: "name_evento" },
    {label: "fecha_evento", value: "fecha_evento" },
    {label: "lugar_evento", value: "lugar_evento" },
    {label: "direccion_evento", value: "direccion_evento" },
    {label: "startsset", value: "startsset" },
    {label: "roomselected", value: "roomselected" },
    {label: "typeofpurchase", value: "typeofpurchase" },
    // {label: "payment_intent", value: "payment_intent" },
    // {label: "payment_intent_secret", value: "payment_intent_secret" },
    {label: "payment_status", value: "payment_status" },
    {label: "idplataforma", value: "idplataforma" },
    {label: "fecha_compra", value: "fecha_compra" },
    {label: "hora_compra", value: "hora_compra" },
    // {label: "iduser", value: "iduser" },
    {label: "company_name", value: "company_name" },
  ];

  const initSelec = { value_option: 0, name_option: "Seleccione una opción" };
  const initSelec2 = { value: 0, text: "Seleccione una opción" };

  const [slsfirsload, setslsfirsload] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [slsCompany, setslsCompany] = useState([initSelec]);
  const [slsBranch, setslsBranches] = useState([initSelec2]);
  const [formValues, setFormValues] = useState({
    company: "",
    branch: "",
  });
  const [slsListCombos, setslsListCombos] = useState([]);
  const [slsListReservations, setslsListReservations] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [slsInfoSales, setslsInfoSales] = useState([]);

  const [filterType, setFilterType] = useState("name_hotel");
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    if (!slsfirsload) {
      fnPostListCombos();
      executeFetchLoadCombos();
      setslsfirsload(true);

      fnPostInfoReportSales([]);
    }
  }, [idUserP, slsfirsload]);

  useEffect(() => {
    if (formValues.company) {
      const fetchBranches = async () => {
        const branches = await fnGetAllBranch();
        setslsBranches([initSelec2, ...branches]);
        fnPostInfoReportSales([]);
      };
      fetchBranches();
    }
  }, [formValues.company]);

  useEffect(() => {
    if (formValues.branch) {
      const updateCombos = async () => {
        await fnPostListCombos();
        fnPostInfoReportSales([]);
      };
      updateCombos();
    }
  }, [formValues.branch]);

  const executeFetchLoadCombos = async () => {
    try {
      if (isLoaded) {
        await fetchLoadCombos();
      }

      const selectCombos = [initSelec, ...(await fnPostCompaniesList())];
      setslsCompany(selectCombos);

      const branches = await fnGetAllBranch();
      setslsBranches([initSelec2, ...branches]);
    } catch (error) {
      console.error("Error en fetchLoadCombos:", error);
    } finally {
      setIsLoaded(false);
    }
  };

  const fetchLoadCombos = async () => {
    try {
      if (!token) {
        token = getFromLocalStorage("TK");
      }
    } catch (error) {
      console.error("Error al cargar los combos:", error);
    }
  };

  const fnPostCompaniesList = async () => {
    try {
      const requestData = {
        id_rol: idUserP.rol,
        id_empresa: idUserP.company,
        id_sucursal: idUserP.branch,
      };

      const responseJsonCompanies = await axios.post(
        postAllCompaniesRoll(token),
        requestData
      );

      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };

  const fnGetAllBranch = async () => {
    try {
      const requestData = {
        companyId: formValues.company || idUserP.company,
      };

      const response = await axios.post(
        getBranchDataByCompany(token),
        requestData
      );
      return response.data.data;
    } catch (error) {
      console.error("Error al cargar las sucursales:", error);
    }
  };

  const fnPostListCombos = async () => {
    try {
      const requestData = {
        id_empresa: formValues.company || idUserP.company,
        id_sucursal: formValues.branch || idUserP.branch,
        id_rol: formValues.branch || idUserP.rol,
      };

      const response = await axios.post(PostCombosRol(token), requestData);
      setslsListCombos(response.data.data || []);
    } catch (error) {
      console.error("Error al cargar los combos:", error);
    }
  };

  const handleInputChangeCompany = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
      ...(name === "company" && { branch: "" }),
    }));
  };

  const fnViewDetail = (idRegister) => {
    const detailData = slsInfoSales.find(
      (datainfo) =>
        datainfo.no_total_reservaciones === idRegister.no_total_reservaciones
    );

    setModalData(detailData);
    setShowModal(true);
  };

  const fnPostInfoReportSales = async () => {
    try {
      const requestData = {
        id_empresa: Number(formValues.company) || Number(idUserP.company),
        id_sucursal: formValues.branch || idUserP.branch,
        id_rol: formValues.branch || idUserP.rol,
      };

      const response = await axios.post(postInfoSales(), requestData);
      setslsInfoSales(response.data.data || []);
    } catch (error) {
      console.error("Error al cargar los datos de ventas:", error);
    }
  };

  const handleCloseModal = () => {
    setModalData(null);
    setShowModal(false);
  };


  // const filteredResults = slsInfoSales.filter((item) =>
  //   item[filterType]?.toLowerCase().includes(filterText.toLowerCase())
  // );

  const filteredResults = slsInfoSales
  .filter((item) =>
    item[filterType]?.toLowerCase().includes(filterText.toLowerCase())
  )
  .sort((a, b) => {
    const dateA = new Date(`${a.fecha_compra || "1970-01-01"}T${a.hora_compra || "00:00:00"}`);
    const dateB = new Date(`${b.fecha_compra || "1970-01-01"}T${b.hora_compra || "00:00:00"}`);
    return dateB - dateA; // Orden descendente (más reciente primero)
  });




  return (
    <div>
      <div className="row mb-3">
        <Panel>
          <PanelHeader>Sales Records</PanelHeader>
          <PanelBody>

 <div className="col-md-9">
        <select
          className="form-select mb-5px"
          id="typeFilter"
          name="TypeFilter"
          value={filterType}
          onChange={(e) => setFilterType(e.target.value)}
        >
          {selectOptions.map((option, index) => (
            <option key={"form_" + index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      {/* Input para texto del filtro */}
      <div className="row mb-3 d-flex align-items-center">
        <label
          className="form-label col-form-label col-md-3 mb-0"
          htmlFor="nombredestino"
        >
          Text Filter *
        </label>
        <div className="col-md-4">
          <input
            className="form-control"
            type="text"
            id="nombredestino"
            name="nombredestino"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </div>
      </div>


            <div className="row col-md-12">
              <DataTableConsulting
                header={header}
                headerMap={headerMap}
                info={filteredResults}
                onViewDetail={fnViewDetail}
              />
            </div>

            {/* <div className="col-md-12">
        <h5>Filtered Results:</h5>
        <ul>
          {filteredResults.map((item, index) => (
            <li key={index}>
              {item.name_hotel} - {item.city} - {item.country}
            </li>
          ))}
        </ul>
      </div> */}
          </PanelBody>
        </Panel>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Reservation Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalData ? (
            <div>
              <p> <strong>hotel:</strong> {modalData.name_hotel} Cancún </p>
              <p> <strong>url_hoteli:</strong> {modalData.url_hotel} </p>
              <p> <strong>hotelcode:</strong> {modalData.hotelcode} </p>
              <p> <strong>roomdescription:</strong> {modalData.roomdescription} </p>
              <p> <strong>confirmpropertycode:</strong>{" "}   {modalData.confirmpropertycode} </p>
              <p> <strong>confirmation_number:</strong>{" "}   {modalData.confirmation_number} </p>
              <p> <strong>precio_noche:</strong> {modalData.precio_noche} </p>
              <p> <strong>precio_total:</strong> {modalData.precio_total} </p>
              <p>
                <strong>precio_total_withcomission:</strong>{" "}
                {modalData.precio_total_withcomission}
              </p>
              <p> <strong>taxes:</strong> {modalData.taxes} </p>
              <p> <strong>nombre:</strong> {modalData.nombre} </p>
              <p> <strong>last_name:</strong> {modalData.last_name} </p>
              <p> <strong>number_movil:</strong> {modalData.number_movil} </p>
              <p> <strong>email:</strong> {modalData.email} </p>
              <p> <strong>city:</strong> {modalData.city} </p>
              <p> <strong>nationality:</strong> {modalData.nationality} </p>
              <p> <strong>idboletogroup:</strong> {modalData.idboletogroup} </p>
              <p> <strong>l_address:</strong> {modalData.l_address} </p>
              <p> <strong>l_latitude:</strong> {modalData.l_latitude} </p>
              <p> <strong>l_longitude:</strong> {modalData.l_longitude} </p>
              <p> <strong>ls_city:</strong> {modalData.ls_city} </p>
              <p> <strong>ls_idevent:</strong> {modalData.ls_idevent} </p>
              <p> <strong>ls_typesupplier:</strong> {modalData.ls_typesupplier} </p>
              <p> <strong>ls_numtickts:</strong> {modalData.ls_numtickts} </p>
              <p> <strong>price_tix:</strong> {modalData.price_tix} </p>
              <p> <strong>price_v:</strong> {modalData.price_v} </p>
              <p> <strong>section_v:</strong> {modalData.section_v} </p>
              <p> <strong>row_v:</strong> {modalData.row_v} </p>
              <p> <strong>name_evento:</strong> {modalData.name_evento} </p>
              <p> <strong>fecha_evento:</strong> {modalData.fecha_evento} </p>
              <p> <strong>lugar_evento:</strong> {modalData.lugar_evento} </p>
              <p> <strong>direccion_evento:</strong> {modalData.direccion_evento} </p>
              <p> <strong>startsset:</strong> {modalData.startsset} </p>
              <p> <strong>roomselected:</strong> {modalData.roomselected} </p>
              <p> <strong>typeofpurchase:</strong> {modalData.typeofpurchase} </p>
              <p> <strong>payment_intent:</strong> {modalData.payment_intent} </p>
              <p> <strong>payment_intent_secret:</strong>{" "}{modalData.payment_intent_secret} </p>
              <p> <strong>payment_status:</strong> {modalData.payment_status} </p>
              <p> <strong>idplataforma:</strong> {modalData.idplataforma} </p>
              <p> <strong>fecha_compra:</strong> {modalData.fecha_compra} </p>
              <p> <strong>hora_compra:</strong> {modalData.hora_compra} </p>
              <p> <strong>iduser:</strong> {modalData.iduser} </p>
              <p> <strong>company_name:</strong> {modalData.company_name} </p>
            </div>
          ) : (
            <p>Cargando...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <ReactNotifications />
    </div>
  );
}

export default ReportView;
