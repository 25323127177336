import React from 'react';
import { getFromLocalStorage } from "../../components/helps.js";


interface DataTableProps {
    header: string[];
    headerMap: Record<string, string>;
    info: any[];
    onViewDetail?: (id: any) => void;
    onEditRegister?: (id: any) => void;
    onDelete?: (id: any) => void;
    viewOnly: boolean

}

const DataTableConsulting: React.FC<DataTableProps> = ({ 
    header, 
    headerMap, 
    info, 
    onViewDetail, 
    onEditRegister, 
    onDelete, 
    viewOnly = false 
}) => {
    const validHeader = Array.isArray(header) ? header : [];

    let idUserP = getFromLocalStorage("Session");

    // Verificar el rol de idUserP
    const canViewDetails = [1, 2, 4, 6, 3, 5, 7].includes(idUserP?.rol);
    const canEditDelete = [1, 2, 4, 6].includes(idUserP?.rol);

    return (
        <div className='table-responsive'>
            <table className='table table-bordered table-striped'>
                <thead>
                    <tr>
                        {validHeader.map((item, index) => (
                            <th key={"head_" + index} style={item.includes("_id") || item.includes("id_") ? { display: "none" } : {}}>
                                {headerMap[item] || item}
                            </th>
                        ))}
                        {(!viewOnly && (onViewDetail || onEditRegister || onDelete)) && <th>Acciones</th>}
                    </tr>
                </thead>
                <tbody>
                    {info.map((item, index) => (
                        <tr key={"body-" + index}>
                            {validHeader.map((headerItem, index2) => (
                                <td key={"body_" + index + "_" + index2} style={headerItem.includes("_id") || headerItem.includes("id_") ? { display: "none" } : {}}>
                                    {typeof item[headerItem] === "boolean" ? (item[headerItem] ? "Active" : "Inactive") : item[headerItem]}
                                </td>
                            ))}
                            {(!viewOnly && (onViewDetail || onEditRegister || onDelete)) && (
                                <td key={"body_" + index + "_Accion"}>
                                    <div className='btn-group'>
                                        {canViewDetails && onViewDetail && (
                                            <button
                                                type="button"
                                                className="btn btn-info"
                                                onClick={() => onViewDetail(item)}
                                                aria-label="View Details"
                                            >
                                                Ver Detalle
                                            </button>
                                        )}
                                        {canEditDelete && (
                                            <>
                                                {onEditRegister && (
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={() => onEditRegister(item)}
                                                    >
                                                        Editar
                                                    </button>
                                                )}
                                                {onDelete && (
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger"
                                                        onClick={() => onDelete(item)}
                                                    >
                                                        Eliminar
                                                    </button>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};



export default DataTableConsulting;
