import React, { useState, useEffect } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import { ReactNotifications } from "react-notifications-component";
import {
  getBranchDataByCompany,
  PostCombosRol,
  postAllCompaniesRoll,
} from "../../utils/urls.js";
import { getFromLocalStorage } from "../../components/helps.js";
import axios from "axios";
import DataTableConsulting from "../../components/objects/tablesConsulta.tsx";


function ListComboViewhotels({ idUser, infoUser, dataUser }) {
  let token = getFromLocalStorage("TK");
  let idUserP = getFromLocalStorage("Session");

  const header = ["id", "name", "description", "available", "id_empresa", "id_sucursal"];
  const headerMap = {id: "Id", name: "Name", description: "Description", available: "Status", id_empresa: "Company", id_sucursal: "Branch"};

  const initSelec = { value_option: 0, name_option: "Seleccione una opcion" };
  const initSelec2 = { value: 0, text: "Seleccione una opcion" };

  const [slsfirsload, setslsfirsload] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [slsCompany, setslsCompany] = useState([initSelec]);
  const [slsBranch, setslsBranches] = useState([initSelec2]);
  const [formValues, setFormValues] = useState({
    company: "",
    branch: "",
  });
  const [slsListCombos, setslsListCombos] = useState([]);

  useEffect(() => {
    if (!slsfirsload) {
      fnPostListCombos();
      executeFetchLoadCombos();
      setslsfirsload(true);
    }
  }, [idUserP, slsfirsload]);

  useEffect(() => {
    if (formValues.company) {
      const fetchBranches = async () => {
        const branches = await fnGetAllBranch();
        setslsBranches([initSelec2, ...branches]);
        setslsListCombos([])
      };
      fetchBranches();
    }
  }, [formValues.company]);

  useEffect(() => {
    if (formValues.branch) {
      const updateCombos = async () => {
        await fnPostListCombos(); // Asegúrate de actualizar la lista de combos
      };
      updateCombos();
    }
  }, [formValues.branch]);

  const executeFetchLoadCombos = async () => {
    try {
      if (isLoaded) {
        await fetchLoadCombos();
      }

      const selectCombos = [initSelec, ...(await fnPostCompaniesList())];
      setslsCompany(selectCombos);

      const branches = await fnGetAllBranch();
      setslsBranches([initSelec2, ...branches]);
    } catch (error) {
      console.error("Error en fetchLoadCombos:", error);
    } finally {
      setIsLoaded(false);
    }
  };

  const fetchLoadCombos = async () => {
    try {
      if (!token) {
        token = getFromLocalStorage("TK");
      }
    } catch (error) {
      console.error("Error al cargar los combos:", error);
    }
  };

  const fnPostCompaniesList = async () => {
    try {
      const requestData = {
        id_rol: idUserP.rol, 
        id_empresa: idUserP.company,
        id_sucursal: idUserP.branch
      };
  
      const responseJsonCompanies = await axios.post(postAllCompaniesRoll(token), requestData);

      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };

  const fnGetAllBranch = async () => {
    try {
      const requestData = {
        companyId: formValues.company || idUserP.company,
      };

      const response = await axios.post(
        getBranchDataByCompany(token),
        requestData
      );
      return response.data.data;
    } catch (error) {
      console.error("Error al cargar las sucursales:", error);
    }
  };

  const fnPostListCombos = async () => {
    try {
      const requestData = {
        id_empresa: formValues.company || idUserP.company,
        id_sucursal: formValues.branch || idUserP.branch,
        id_rol: formValues.branch || idUserP.rol,
      };

      const response = await axios.post(PostCombosRol(token), requestData);
      setslsListCombos(response.data.data || []);
    } catch (error) {
      console.error("Error al cargar los combos:", error);
    }
  };

  const handleInputChangeCompany = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
      ...(name === "company" && { branch: "" }),
    }));
  };

  const handleDetail = (id) => {
    console.log("Detail for combo ID:", id);
    // Lógica para detalle
  };
  
  const handleEdit = (id) => {
    console.log("Edit for combo ID:", id);
    // Lógica para editar
  };

  console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<slsListCombos>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')

  console.log(slsListCombos)

  const fnViewDetail= (idRegister) => {

};

const fnEditRegister = (idRegister) => {
console.log('ID recibido para editar:', idRegister); // Asegúrate de imprimir el ID aquí
};

const fnDeleteRegister = async (idRegister) => {
};

  return (
    <div>

      {/* <div className="row mb-3">
        <Panel>
          <PanelHeader>Best Value Offers</PanelHeader>
          <PanelBody>
            <div>
              {(idUserP.rol === 1 || idUserP.rol === 2) && (
                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="company"
                  >
                    Companies*
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select mb-5px"
                      id="company"
                      name="company"
                      value={formValues.company}
                      onChange={handleInputChangeCompany}
                    >
                      {slsCompany.map((option, index) => (
                        <option
                          key={"form_" + index}
                          value={option.value_option}
                        >
                          {option.name_option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              {(idUserP.rol === 1 ||
                idUserP.rol === 2 ||
                idUserP.rol === 4 ||
                idUserP.rol === 6) && (
                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="branch"
                  >
                    Branches
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select mb-5px"
                      id="branch"
                      name="branch"
                      value={formValues.branch}
                      onChange={handleInputChangeCompany}
                    >
                      {slsBranch.map((option, index) => (
                        <option key={"branch_" + index} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              *selecciona una opción de los combos activos.
            </div>
            <div className='row col-md-12'>
								<DataTableConsulting header={header} headerMap={headerMap} info={slsListCombos} onViewDetail={fnViewDetail} onEditRegister={fnEditRegister} onDelete={fnDeleteRegister} />
							</div>
          </PanelBody>
        </Panel>

      </div> */}
      
      <div className="row mb-3">
        <Panel>
          <PanelHeader>List Combos Hotels</PanelHeader>
          <PanelBody>
            <div>
              {(idUserP.rol === 1 || idUserP.rol === 2) && (
                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="company"
                  >
                    Companies*
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select mb-5px"
                      id="company"
                      name="company"
                      value={formValues.company}
                      onChange={handleInputChangeCompany}
                    >
                      {slsCompany.map((option, index) => (
                        <option
                          key={"form_" + index}
                          value={option.value_option}
                        >
                          {option.name_option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              {(idUserP.rol === 1 ||
                idUserP.rol === 2 ||
                idUserP.rol === 4 ||
                idUserP.rol === 6) && (
                <div className="row md-15px">
                  <label
                    className="form-label col-form-label col-md-3"
                    htmlFor="branch"
                  >
                    Branches
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select mb-5px"
                      id="branch"
                      name="branch"
                      value={formValues.branch}
                      onChange={handleInputChangeCompany}
                    >
                      {slsBranch.map((option, index) => (
                        <option key={"branch_" + index} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              *selecciona una opción de los combos activos.
            </div>

            <div className='row col-md-12'>
								<DataTableConsulting header={header} headerMap={headerMap} info={slsListCombos} onViewDetail={fnViewDetail} onEditRegister={fnEditRegister} onDelete={fnDeleteRegister} />
							</div>
          </PanelBody>
        </Panel>



      </div>
      <ReactNotifications />
    </div>
  );
}

export default ListComboViewhotels;
