import React, { useState, useEffect } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import { ReactNotifications, Store } from "react-notifications-component";
import {
  ListContryCitys,
  postAllCompaniesRoll,
  getBranchDataByCompany,
  GetInfoDestinos,
  PostEditinfoDestin,
} from "../../utils/urls.js";
import {
  getListhotelsview,
} from "../../utils/urlExperience.js";
import { getFromLocalStorage } from "../../components/helps.js";
import axios from "axios";

function EditDestinations({ id, infoRegister }) {
    const [isEditMode, setIsEditMode] = React.useState(false); // Control de visibilidad del componente

  let token = getFromLocalStorage("TK");
  let idUserP = getFromLocalStorage("Session");

  const header = ["id", "nombre", "urlimagen", "statename", "show"];
  const headerMap = {
    id: "Id",
    nombre: "Name",
    urlimagen: "Image",
    statename: "Country",
    show: "Status",
  };
  const initSelec2 = [{ value: 0, text: "Select an option" }];
  const initCountrys = {
    country: "",
    citys: [
      {
        city: "",
        citycode: 0,
      },
    ],
  };

  const initHotels = {
    idbvo: 0,
    nombredestino: "",
    urlimagen: "",
    place: "",
    statename: "",
    show: false,
    idcliente: 0,
  };

  const initDestin = {
    id: 0,
    nombre: "",
    urlimagen: "",
    place: "",
    statename: "",
    show: false,
    id_empresa: 0,
    id_sucursal: 0,
  };

  const initSelec = { value_option: 0, name_option: "Select an option" };

  const [idCompanys, setidCompanys] = useState([initSelec2]);
  const [slsCompany, setslsCompany] = useState([]);
  const [slsCompanyArray, setslsCompanyArray] = useState([initCountrys]);
  const [slsHotelsArray, setslsHotelsArray] = useState([initHotels]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const initSelec3 = { name: "", lastname: "", email: "", userId: "", rol: "" };
  const [isLoaded, setIsLoaded] = useState(true);
  const [formValues, setFormValues] = useState({
    nombredestino: "",
    urlimagen: null,
    place: "",
    statename: "",
    show: true,
    idcliente: initSelec.value_option,
    company: "",
    branch: "",
    useForAllBranches: false,
  });

  const [formValuesSelects, setFormValuesSelects] = useState({
    company: "",
    branch: "",
  });

  const [slsDataDestinos, setslsDataDestinos] = useState([initDestin]);
  const [data, setData] = useState([]);
  const [slsRoll, setslsRoll] = useState([initSelec2]);
  const [slsBranch, setslsBranches] = useState([initSelec2]);
  const [slsBranchesViews, setslsBranchesViews] = useState([initSelec2]);
  const [slsUsersbyComp, setslsUsersbyComp] = useState([initSelec3]);

  const [tableValues, setTableValues] = useState({ company: "" });
  const [isPanel1Visible, setIsPanel1Visible] = useState(false);

  const [cityOptions, setCityOptions] = useState([]); // Nuevo estado para las ciudades
  

  useEffect(() => {
    const executeFetchLoadCombos = async () => {
      try {
        if (isLoaded === true) {
          await fetchLoadCombos();
          console.log("fetchLoadCombos ejecutado con éxito");
        }
      } catch (error) {
        console.error("Error en fetchLoadCombos:", error);
      } finally {
        setIsLoaded(false);
      }
    };

    executeFetchLoadCombos();
  }, [isLoaded]);

  useEffect(() => {
    if (formValues.idcliente) {
      const fetchBranches = async () => {
        try {
          const branches = await fnGetAllBranch();
          if (branches && Array.isArray(branches)) {
            setslsBranches([initSelec2, ...branches]);
          } else {
            setslsBranches([initSelec2]);
          }
        } catch (error) {
          console.error("Error al cargar los combos", error);
          setslsBranches([initSelec2]);
        }
      };
      fetchBranches();
    } else {
      setslsBranches([initSelec2]);
    }
  }, [formValues.idcliente]);

  useEffect(() => {
    if (formValuesSelects.company) {
      const fetchBranches = async () => {
        const branches = await fnGetAllBranch2();
        setslsBranchesViews([initSelec2, ...branches]);
      };
      fetchBranches();
    }

    if (formValuesSelects.branch) {
      const fetchInfoDestinos = async () => {
        const destinations = await fnPostInfoDestios();

        const renamedDestinations = destinations.map((destino) => ({
          ...destino,
          id: destino.id_destino,
        }));

        setslsDataDestinos(renamedDestinations);
      };
      fetchInfoDestinos();
    }
  }, [formValuesSelects.company, formValuesSelects.branch]);


  useEffect(() => {
    // Asignar los valores a los campos del formulario cuando infoRegister esté disponible
    setFormValues({
      nombredestino: infoRegister.nombre || '',
      urlimagen: infoRegister.urlimagen || '',
      statename: infoRegister.statename || '',
      place: infoRegister.place || '', // Dejarlo en blanco como se requiere
      idcliente: infoRegister.id_empresa || '',
      branch: infoRegister.id_sucursal || '',
    });
  }, [infoRegister]);



  const fnGetAllBranch = async () => {
    try {
      const requestData = {
        companyId: formValues.idcliente || idUserP.company,
      };

      const responseJsonCompanies = await axios.post(
        getBranchDataByCompany(token),
        requestData
      );
      return responseJsonCompanies.data.data || [];
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
      return [];
    }
  };

  const fnPostInfoDestios = async () => {
    try {
      const requestData = {
        id_empresa: formValuesSelects.company || idUserP.company,
        id_sucursal: formValuesSelects.branch || 0,
      };

      const responseJsonCompanies = await axios.post(
        GetInfoDestinos(token),
        requestData
      );
      return responseJsonCompanies.data.data || [];
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
      return [];
    }
  };

  const fnGetAllBranch2 = async () => {
    try {
      const requestData = {
        companyId: formValuesSelects.company || idUserP.company,
      };

      const responseJsonCompanies = await axios.post(
        getBranchDataByCompany(token),
        requestData
      );
      return responseJsonCompanies.data.data || [];
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
      return [];
    }
  };

  const fetchLoadCombos = async () => {
    console.log("en funcion de combos <<<<<<<<<<<<<<");

    try {
      if (token === undefined || token === null) {
        token = getFromLocalStorage("TK");
      }
      let selectCombos = [initSelec, ...(await fnPostCompaniesList())];
      let selectContrys = [initCountrys, ...(await listContrysCitys())];
      let selectHotels = [initHotels, ...(await getListHotelsavailables())];

      setslsCompany(selectCombos);
      setslsCompanyArray(selectContrys);
      setslsHotelsArray(selectHotels);
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };

  const getListHotelsavailables = async () => {
    try {
      if (token === undefined || token === null) {
        token = getFromLocalStorage("TK");
      }

      const responseJsonHotelss = await axios.get(getListhotelsview());
      return responseJsonHotelss.data;
    } catch (error) {
      console.error("Error al cargar los combos ", error);
    }
  };

  const listContrysCitys = async () => {
    try {
      const responseJsonContrys = await axios.get(ListContryCitys());
      return responseJsonContrys.data;
    } catch (error) {
      console.error("Error al cargar los combos 11", error);
    }
  };

  const fnPostCompaniesList = async () => {
    try {
      const requestData = {
        id_rol: idUserP.rol,
        id_empresa: idUserP.company,
        id_sucursal: idUserP.branch,
      };

      const responseJsonCompanies = await axios.post(
        postAllCompaniesRoll(token),
        requestData
      );
      return responseJsonCompanies.data.data;
    } catch (error) {
      console.error("Error al cargar los combos 333", error);
    }
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setFormValues((prevValues) => ({
      ...prevValues,
      statename: selectedCountry,
      place: "", // Reiniciar el lugar al cambiar de país
    }));

    const selectedCountryData = slsCompanyArray.find(
      (country) => country.country === selectedCountry
    );

    setCityOptions(selectedCountryData ? selectedCountryData.citys : []);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { nombredestino, urlimagen, statename, place } = formValues;

    if (!nombredestino || !urlimagen || !statename || !place) {
      Store.addNotification({
        title: "Warning",
        message: "All fields are required!",
        type: "warning",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
      return;
    }

    // console.log("Form data submitted:", formValues);

    const formData = new FormData();
    formData.append("id_destino", id);
    formData.append("nombre", formValues.nombredestino);
    formData.append("urlimagen", formValues.urlimagen); // Agrega el archivo
    formData.append("place", formValues.place);
    formData.append("statename", formValues.statename);
    formData.append("id_empresa", formValues.idcliente);
    formData.append("id_sucursal", formValues.branch);

    // console.log('<<<<<<<<<<<<<<<<<<formData>>>>>>>>>>>>>>>>>>')

    // console.log("FormData contenido:");
    // for (let [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }

    const response = await fetch(PostEditinfoDestin(token), {
      method: "POST",
      body: formData,
    });

    console.log('<<<<<<<<response>>>>>>>>')

    console.log(response)
    if(response.status == 201){

        addNotification("success", "Success", "Destination updated successfully.", "top-center");
    }else{
        addNotification(
            "warning",
            "Partial Success",
            "Oops, there was an error during the update.",
            "top-center"
          );
    }


    // Add logic for form submission here
  };


  function addNotification(
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition,
    notificationContent
  ) {
    if (notificationContent) {
      notificationContent = (
        <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
          <div className="flex-1 ps-2">
            <h6 className="mb-1">Christopher Struth</h6>
            <p className="mb-0">Bank Transfer</p>
          </div>
        </div>
      );
    }
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
      content: notificationContent,
    });
  }


  console.log('infoRegister <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<')
  console.log(infoRegister)

  return (
    <Panel>
      <PanelHeader>Manage Destinations</PanelHeader>
      <PanelBody>
        <form onSubmit={handleSubmit}>
          <div className="row mb-3 d-flex align-items-center">
            <label
              className="form-label col-form-label col-md-3 mb-0"
              htmlFor="nombredestino"
            >
              Name destination*
            </label>
            <div className="col-md-9">
              <input
                className="form-control"
                type="text"
                id="nombredestino"
                name="nombredestino"
                value={formValues.nombredestino}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="row mb-3 d-flex align-items-center">
  <label className="form-label col-form-label col-md-3 mb-0" htmlFor="urlimagen">
    Select an image*
  </label>
  <div className="col-md-9">
    {/* Mostrar la miniatura de la imagen solo si existe */}
    {formValues.urlimagen && (
      <div>
        <img
          src={formValues.urlimagen}
          alt="Image Thumbnail"
          style={{ width: '100px', height: 'auto', marginBottom: '10px' }}
        />
      </div>
    )}

    {/* Campo para seleccionar una nueva imagen */}
    <input
      className="form-control"
      type="file"
      id="urlimagen"
      name="urlimagen"
      onChange={handleInputChange} // No es necesario pasar el value porque es un campo de tipo archivo
    />
  </div>
</div>


          <div className="row md-15px">
            <label
              className="form-label col-form-label col-md-3"
              htmlFor="statename"
            >
              Statename*
            </label>
            <div className="col-md-9">
              <select
                className="form-select mb-5px"
                id="statename"
                name="statename"
                value={formValues.statename}
                onChange={handleCountryChange}
              >
                {slsCompanyArray.map((option, index) => (
                  <option key={"form_" + index} value={option.country}>
                    {option.country}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row md-15px">
            <label
              className="form-label col-form-label col-md-3"
              htmlFor="place"
            >
              Place*
            </label>
            <div className="col-md-9">
              <select
                className="form-select mb-5px"
                id="place"
                name="place"
                value={formValues.place}
                onChange={handleInputChange}
              >
                {cityOptions.map((city, index) => (
                  <option key={index} value={city.citycode}>
                    {city.city}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row md-15px">
            <label
              className="form-label col-form-label col-md-3"
              htmlFor="idcliente"
            >
              Companies*
            </label>
            <div className="col-md-9">
              <select
                className="form-select mb-5px"
                id="idcliente"
                name="idcliente"
                value={formValues.idcliente || infoRegister.idcliente}
                onChange={handleInputChange}
              >
                {slsCompany.map((option, index) => (
                  <option key={"form_" + index} value={option.value_option}>
                    {option.name_option}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {(idUserP.rol === 1 ||
            idUserP.rol === 2 ||
            idUserP.rol === 4 ||
            idUserP.rol === 6) && (
            <div className="row md-15px">
              <label
                className="form-label col-form-label col-md-3"
                htmlFor="branch"
              >
                Branches
              </label>
              <div className="col-md-9">
                <select
                  className="form-select mb-5px"
                  id="branch"
                  name="branch"
                  value={formValues.branch || infoRegister.id_sucursal}
                  onChange={handleInputChange}
                >
                  {slsBranch.map((option, index) => (
                    <option key={"form_" + index} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}

          {/* {formValues.idcliente && (
            <div className="row md-15px">
              <label
                className="form-label col-form-label col-md-3"
                htmlFor="useForAllBranches"
              >
                Use for all branches
              </label>
              <div className="col-md-9 d-flex align-items-center">
                <input
                  className="form-check-input is-valid"
                  type="checkbox"
                  id="useForAllBranches"
                  name="useForAllBranches"
                  style={{ marginRight: "10px" }}
                  checked={formValues.useForAllBranches || false}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      useForAllBranches: e.target.checked,
                    })
                  }
                />
              </div>
            </div>
          )} */}

          <div className="lrow md-15px" style={{ margin: 10 }}>
            <button
              type="submit"
              className="btn btn-green"
              style={{ float: "right" }}
            >
              Update record
            </button>
          </div>
        </form>
      </PanelBody>
    </Panel>
  );
}

export default EditDestinations;
